<template>
	<v-list style="width: 100%; background-color: transparent" class="pl-2 py-0">
		<layer-list-item
			v-for="(element, index) in elementsList"
			v-model="self[element]"
			:title="elements[element]"
			:key="element"
			:border-bottom="index < elementsList.length - 1"
		></layer-list-item>
	</v-list>
</template>

<script>
import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';
import LayerListItem from './common/LayerListItem';
import {
	STOP_PREVISION_GROUP_ID,
	STOP_ACCEPTED_LAYER_ID,
	STOP_PLANNED_LAYER_ID,
	STOP_AUTHORIZED_LAYER_ID,
	STOP_CONFIRMED_LAYER_ID,
	STOP_INITIATED_LAYER_ID
} from '@/components/operations/map/constants/layers';

const key = STOP_PREVISION_GROUP_ID;

export default {
	name: 'previsionlayerslist',

	components: {
		LayerListItem
	},

	props: {
		active: {
			type: Boolean,
			required: true
		}
	},

	data() {
		return {
			elements: {
				[STOP_ACCEPTED_LAYER_ID]: this.$t('operations.layers.previsionObj.accepted'),
				[STOP_PLANNED_LAYER_ID]: this.$t('operations.layers.previsionObj.pending'),
				[STOP_AUTHORIZED_LAYER_ID]: this.$t('operations.layers.previsionObj.authorized'),
				[STOP_CONFIRMED_LAYER_ID]: this.$t('operations.layers.previsionObj.confirmed'),
				[STOP_INITIATED_LAYER_ID]: this.$t('operations.layers.previsionObj.iniciated')
			},
			statusElements: {
				['AC']: STOP_ACCEPTED_LAYER_ID,
				['PL']: STOP_PLANNED_LAYER_ID,
				['AU']: STOP_AUTHORIZED_LAYER_ID,
				['CO']: STOP_CONFIRMED_LAYER_ID,
				['IN']: STOP_INITIATED_LAYER_ID
			}
		};
	},

	computed: {
		getStatusStopsPortAutority() {
			let statusid = [];
			const stats = this.$store.getters.stopsStatusValues;
			if (stats) {
				stats.forEach((element) => {
					if (element.visiblemaps) {
						statusid.push(element.stopstatuscode);
					}
				});
			}

			return statusid;
		},
		self() {
			return this;
		},

		elementsList() {
			const matchingStatusElements = Object.fromEntries(
				Object.entries(this.statusElements).filter(([key]) => this.getStatusStopsPortAutority.includes(key))
			);
			const matchingElements = Object.fromEntries(
				Object.entries(this.elements).filter(([key, value]) => Object.values(matchingStatusElements).includes(key))
			);
			const matchingElementKeys = Object.keys(matchingElements);
			return matchingElementKeys;
		},

		anyActive() {
			return this.elementsList.map((key) => this[key]).some(Boolean);
		},

		[`${STOP_ACCEPTED_LAYER_ID}`]: {
			get() {
				return this.$store.getters.layers[key][STOP_ACCEPTED_LAYER_ID];
			},

			set(active) {
				this.setLayerGroupActive(STOP_ACCEPTED_LAYER_ID, active);
			}
		},

		// Planned
		[`${STOP_PLANNED_LAYER_ID}`]: {
			get() {
				return this.$store.getters.layers[key][STOP_PLANNED_LAYER_ID];
			},

			set(active) {
				this.setLayerGroupActive(STOP_PLANNED_LAYER_ID, active);
			}
		},

		[`${STOP_AUTHORIZED_LAYER_ID}`]: {
			get() {
				return this.$store.getters.layers[key][STOP_AUTHORIZED_LAYER_ID];
			},

			set(active) {
				this.setLayerGroupActive(STOP_AUTHORIZED_LAYER_ID, active);
			}
		},
		[`${STOP_CONFIRMED_LAYER_ID}`]: {
			get() {
				return this.$store.getters.layers[key][STOP_CONFIRMED_LAYER_ID];
			},

			set(active) {
				this.setLayerGroupActive(STOP_CONFIRMED_LAYER_ID, active);
			}
		},
		[`${STOP_INITIATED_LAYER_ID}`]: {
			get() {
				return this.$store.getters.layers[key][STOP_INITIATED_LAYER_ID];
			},

			set(active) {
				this.setLayerGroupActive(STOP_INITIATED_LAYER_ID, active);
			}
		}
	},
	methods: {
		toggleAll() {
			this.elementsList.forEach((key) => (this[key] = this.active));
		},

		updateActive(active) {
			this.$emit('update:active', active);
		},

		setLayerGroupActive(subLayer, active) {
			this.$store.dispatch('setSubLayerGroupActive', { key, subLayer, active });
			ol2map.setVisibility(subLayer, active);

			if (active) {
				this.$emit('update:active', true);
			}
		}
	},

	watch: {
		anyActive: {
			handler: 'updateActive',
			immediate: true
		}
	}
};
</script>
